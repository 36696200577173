import './static.css'

import React, { useState } from 'react';

import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faChevronRight, faTimesCircle, faExclamationCircle , faServer } from '@fortawesome/free-solid-svg-icons';

import { config } from '../../config';

const ELKStarting = ({ app }) => {
    console.log('ELKStarting begin');
    
    console.log('ELKStarting end');

    return (
        <div>
            <Container className="p-0" fluid>
                <div>
                    
                    <main>
                            <div className="success wait-title">
                            <FontAwesomeIcon icon={faCheckCircle} className="enlarge" />
                                            <span className="txt-product-installed">  We have created {config.appName} server for you.</span>
                            </div>
                            <div className="wait-content">
                                            <span className="txt-product-installed"> We are currently configuring {config.appName}'s dashboards. </span> <br/><span className="txt-product-installed">Your Dashboards will be ready in few minutes</span>
                            </div>
                            
                    </main>
                </div>
            </Container>
        </div>
    );
}
export default ELKStarting