import './Wizard.css'

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Container, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faChevronRight, faTimesCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { config } from '../../config';


const Start = ({ app }) => {
    console.log('Start begin');
    console.log('Start, app = ' + app);

    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [productAvailable, setProductAvailable] = useState();
    const [userName, setUserName] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        console.log('Start useEffect begin, app = ' + app);

        const init = async () => {
            try {
                if (app) {
                    console.log('Start, isAvailable = ' + app.isAvailable);
                    setProductAvailable(app.isAvailable);

                    const user = await app.getUserDetails();
                    console.log('Start, user = ' + user);

                    if (!user) {
                        throw new Error('Could not get user');
                    }

                    if (!(user.authorization && user.authorization.roles)) {
                        throw new Error('Could not get user roles');
                    }

                    console.log('Start, setupPermissionsRequired = ' + config.setupPermissionsRequired);

                    const missingRoles = config.setupPermissionsRequired.filter(p => !user.authorization.roles.find(r => r.name === p));
                    console.log('Start, missingRoles = ' + missingRoles);

                    if (missingRoles.length) {
                        throw new Error(`Missing role(s) to run the installer - ${missingRoles}`);
                    }

                    setLoading(false);
                    setUserName(user && user.name);
                }
            }
            catch (err) {
                console.log('Start, error = ' + err);

                setLoading(false);
                setError(err.body && err.body.message || err.toString());
            }
        }

        init();

        console.log('Start useEffect end');

    }, [app]);

    console.log('Start end');

    return (
        <div>
            <Container className="p-0" fluid>
                {loading && (
                    <div className="spinner-container">
                        <Spinner color="secondary" />
                    </div>
                )}

                {!loading && (
                    <div>
                        <div className="title">
                            <span className="txt-greeting">Hi</span>
                            {userName && (
                                <span>&nbsp;{userName}</span>
                            )}!
                            <div className="txt-greeting-2">
                                Welcome to the {config.appName} application
                            </div>
                        </div>

                        <ul className="progress-bar">
                            <li className="active current">
                                <span className="txt-start">Start</span>
                            </li>
                            <li className="">
                                <span className="txt-install">Install</span>
                            </li>
                            <li className="">
                                <span className="txt-summary">Summary</span>
                            </li>
                        </ul>

                        <main>
                            {!error && (
                                <div>
                                    {productAvailable && (
                                        <div>
                                            <p className="success">
                                                <span className="txt-product-available">Product Available</span>&nbsp;
                                                <FontAwesomeIcon icon={faCheckCircle} className="enlarge" />
                                            </p>
                                            <p>
                                                <span className="txt-available-message">We're ready to set you up. Please click the Start button.</span>
                                            </p>
                                            <button className="btn-info right" onClick={() => { history.push('/wizard/install') }}>
                                                <span className="txt-start">Start</span>&nbsp;
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </button>
                                        </div>
                                    )}

                                    {!productAvailable && (
                                        <div>
                                            <p className="fail">
                                                <span className="txt-product-not-available">Product not available</span>&nbsp;
                                                <FontAwesomeIcon icon={faTimesCircle} className="enlarge" />
                                            </p>
                                            <p>
                                                <span className="txt-not-available-message">
                                                    We're sorry but your Genesys Cloud organization does not have the {config.appName} product enabled.
                                                    Please contact the authorities.
                                                </span>
                                            </p>
                                        </div>
                                    )}
                                </div>
                            )}

                            {error && (
                                <div>
                                    <p className="fail">
                                        <span className="txt-product-error">Product could not be installed</span>&nbsp;
                                        <FontAwesomeIcon icon={faExclamationCircle} className="enlarge" />
                                    </p>
                                    <p>
                                        <span className="txt-error-message">{error}</span>
                                    </p>
                                </div>
                            )}
                        </main>
                    </div>
                )}
            </Container>
        </div >
    )
}
export default Start