import { config } from '../config';

const $ = window.$; // jquery

export const getProperties = (accessToken, environment) => {
   console.log('getProperties begin');

   return new Promise((resolve, reject) => {
      const request = {
         type: 'GET',
         headers: {
            token: accessToken || localStorage.getItem(config.integrationType + ":accessToken"),
            env: environment || localStorage.getItem(config.integrationType + ":environment") || config.defaultEnvironment,
            tokensource: 'purecloud'
         },
         url: config.endpoints.api + '/properties',
         dataType: 'json',
         data: null,
      };
      console.log('getProperties, request = ' + JSON.stringify(request, null, 3));

      if (!request.headers.token) {
         // skip server call
         const message = 'Token not available';
         
         console.log('getProperties end, reject = ' + message);
         return reject(new Error(message));
      }

      $.ajax(request)
         .done((response) => {
            //console.log('getProperties end, resolve = ' + JSON.stringify(response.properties, null, 3));
            console.log('getProperties end');
            return resolve(response.properties);
         })
         .fail((xhr, status, error) => {
            console.log('getProperties, xhr = ' + JSON.stringify(xhr, null, 3));
            console.log('getProperties, status = ' + JSON.stringify(status, null, 3));
            console.log('getProperties, error = ' + JSON.stringify(error, null, 3));

            const message = (xhr && xhr.responseJSON && xhr.responseJSON.message) || 'Could not get properties';

            console.log('getProperties end, reject = ' + message);
            return reject(new Error(message));
         });

   });
};

export const setProperties = (properties, accessToken, environment) => {
   console.log('setProperties begin');

   return new Promise((resolve, reject) => {
      const request = {
         type: 'POST',
         headers: {
            token: accessToken || localStorage.getItem(config.integrationType + ":accessToken"),
            env: environment || localStorage.getItem(config.integrationType + ":environment") || config.defaultEnvironment,
            tokensource: 'purecloud'
         },
         url: config.endpoints.api + '/setProperties',
         contentType: 'application/json',
         dataType: 'json',
         data: JSON.stringify(properties),
      };
      console.log('setProperties, request = ' + JSON.stringify(request, null, 3));

      $.ajax(request)
         .done((response) => {
            console.log('setProperties end, resolve = ' + JSON.stringify(response, null, 3));
            return resolve(response);
         })
         .fail((xhr, status, error) => {
            console.log('setProperties, xhr = ' + JSON.stringify(xhr, null, 3));
            console.log('setProperties, status = ' + JSON.stringify(status, null, 3));
            console.log('setProperties, error = ' + JSON.stringify(error, null, 3));

            const message = (xhr && xhr.responseJSON && xhr.responseJSON.message) || 'Could not set properties';

            console.log('setProperties end, reject = ' + message);
            return reject(new Error(message));
         });

   });
};

