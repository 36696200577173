// https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications

import React, { useState } from 'react'
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

import Header from '../components/header/Header';
import Main from '../components/Main';
import Home from '../components/home/Home';
import Settings from '../components/settings/Settings';
import Help from '../components/help/Help';
import Start from '../components/wizard/Start';
import Install from '../components/wizard/Install';
import Summary from '../components/wizard/Summary';
import Maintanence from '../components/static/Maintanence';
import ELKStarting from '../components/static/ELKStarting';

const AppRouter = () => {
    console.log('AppRouter begin');
    const [app, setApp] = useState();

    console.log(`AppRouter, requested resource is  =${window.location.href}`);
    const openHelp = window.location.href.includes('help');
    
    console.log('AppRouter, openHelp = ' + openHelp);

    console.log('AppRouter end');

    if (openHelp) {
        return (
            <BrowserRouter>
                <Header />
                <Help />
            </BrowserRouter>
        )
    }

    // execute this once every time on startup, even if entering URL directly as /settings
    if (!app) {
        return (
            <BrowserRouter>
                <Header />
                <Main setApp={setApp} />
            </BrowserRouter>
        )
    }

    return (
        <div>
            <BrowserRouter>
                <Header app={app} />
                <Switch>
                    <PrivateRoute path="/" exact={true} component={Home} />
                    <PrivateRoute path="/home" exact={true} component={Home} />
                    <PrivateRoute path="/home/:dashboardId" component={Home} />
                    <PrivateRoute path="/settings" exact={true} component={Settings} />
                    <PrivateRoute path="/help" exact={true} component={Help} />
                    <PrivateRoute path="/static/elkstarting" exact={true} component={ELKStarting} app={app} />
                    <PrivateRoute path="/static/maintanence" exact={true} component={Maintanence} app={app} />
                    <PrivateRoute path="/wizard/start" exact={true} component={Start} app={app} />
                    <PrivateRoute path="/wizard/install" exact={true} component={Install} app={app} />
                    <PrivateRoute path="/wizard/summary" exact={true} component={Summary} app={app} />
                    <Redirect from="*" to="/" />
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export default AppRouter