import 'bootstrap/dist/css/bootstrap.min.css'
import './styles.css';

import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';

import AppRouter from './routers/AppRouter';
const jsx = (
    <AppRouter />
)

ReactDOM.render(jsx, document.getElementById('root'));
registerServiceWorker()