import { config } from '../config.js';
import { getRedirectUri } from '../scripts/utils';
import platformClient from 'purecloud-platform-client-v2/dist/node/purecloud-platform-client-v2';

// JQuery Alias
const $ = window.$

export default class Premium {
    constructor() {
        // PureCloud Javascript SDK clients
        this.platformClient = platformClient;
        this.purecloudClient = this.platformClient.ApiClient.instance;
        this.purecloudClient.setPersistSettings(true, 'premium_app');
        this.redirectUri = getRedirectUri();

        // PureCloud API instances
        this.usersApi = new this.platformClient.UsersApi();
        this.integrationsApi = new this.platformClient.IntegrationsApi();
        this.groupsApi = new this.platformClient.GroupsApi();
        this.authApi = new this.platformClient.AuthorizationApi();
        this.oAuthApi = new this.platformClient.OAuthApi();

        this.accessToken = null;
        this.environment = null;
        this.language = null;
        this.isPremium = null;
        this.isInstalled = null;
        this.ELKStatus = null;
    }

    getEntities = (api, func, options) => {
        const entities = [];
        const pageSize = 500;
        let pageNumber = 1;

        const getEntitiesWorker = async () => {
            const data = await api[func]({ ...options, pageSize, pageNumber });
            console.log(`${func}, options: ${JSON.stringify(options)}, pageSize: ${pageSize}, pageNumber: ${pageNumber}, entities: ${data.entities.length}`);

            entities.push(...data.entities);
            return (pageNumber++ < data.pageCount) ? getEntitiesWorker() : entities;

        }
        return getEntitiesWorker();
    }

    /**
     * Get details of the current user
     * @return {Promise.<Object>} PureCloud User data
     */
    getUserDetails = () => {
        return this.usersApi.getUsersMe({ 'expand': ['authorization'] });
    }

    getApplication() {
        console.log('Premium getApplication begin');

        // will get from server{ isPremium, isInstalled, isAvailable }

        return new Promise((resolve, reject) => {
            const request = {
                type: 'GET',
                headers: {
                    token: this.accessToken || localStorage.getItem(config.integrationType + ':accessToken'),
                    env: this.environment || localStorage.getItem(config.integrationType + ':environment'),
                    tokensource: 'purecloud'
                },
                url: config.endpoints.api + '/application',
                contentType: 'application/json',
                dataType: 'json',
                data: null
            };
            console.log('Premium getApplication, request: ' + JSON.stringify(request, null, 3));

            $.ajax(request)
                .done((response) => {
                    console.log('Premium getApplication resolve, response: ' + JSON.stringify(response));
                    return resolve(response);
                })
                .fail((xhr, status, error) => {
                    console.log('Premium getApplication, xhr: ' + JSON.stringify(xhr, null, 3));
                    console.log('Premium getApplication, status: ' + JSON.stringify(status, null, 3));
                    console.log('Premium getApplication, error: ' + JSON.stringify(error, null, 3));

                    const message = (xhr && xhr.responseJSON && xhr.responseJSON.message) || 'Could not get application';

                    console.log('Premium getApplication reject, message = ' + message);
                    return reject(new Error(message));
                });
        });
    }

    //// =======================================================
    ////      ROLES
    //// =======================================================

    /**
    * Get existing roles in purecloud based on prefix
    * @returns {Promise.<Array>} PureCloud Roles
    */
    getExistingRoles = async () => {
        console.log('Premium getExistingRoles begin');

        try {
            const promises = [];

            // could use wildcard but instead will search each role by exact name
            config.provisioningInfo.roles.forEach(role => {
                if (role.type === 'server') {
                    const authOpts = {
                        name: role.name,
                        userCount: false
                    };
                    // Required Permissions - User making this call must have ANY of these permissions: authorization:role:view
                    // Required Scopes - Apps making this call must have one of these scopes: authorization, authorization:readonly
                    promises.push(this.getEntities(this.authApi, 'getAuthorizationRoles', authOpts));
                }
            });

            const results = await Promise.all(promises);

            console.log('Premium getExistingRoles end');
            return results.flatMap(roles => roles); // combine result arrays
        }
        catch (err) {
            console.log('Premium getExistingRoles, error:' + JSON.stringify(err, null, 3));
            throw err;
        }
    }

    /**
     * Delete existing roles from PureCloud
     * @returns {Promise}
     */
    deleteRoles = async () => {
        console.log('Premium deleteRoles begin');

        try {
            const entities = await this.getExistingRoles();
            const promises = [];

            console.log('Premium deleteRoles, entities: ' + JSON.stringify(entities, null, 3));

            entities.forEach(entity => {
                promises.push(this.authApi.deleteAuthorizationRole(entity.id));
            });

            console.log('Premium deleteRoles end');
            return Promise.all(promises);
        }
        catch (err) {
            console.log('Premium deleteRoles, error:' + JSON.stringify(err, null, 3));
            throw err;
        }
    }

    /**
    * Add PureCLoud roles based on installation data
    * @returns {Promise}
    */
    addRoles = async () => {
        console.log('Premium addRoles begin');

        try {
            const user = await this.getUserDetails();
            const roles = [];

            // create roles one by one to show pretty log lines to user
            for (const roleData of config.provisioningInfo.roles) {
                if (this.isPremium || !this.isPremium && roleData.type === 'server') {
                    try {
                        const roleBody = {
                            name: roleData.name,
                            description: roleData.description,
                            permissionPolicies: roleData.permissionPolicies
                        };

                        console.log('Premium addRoles, postAuthorizationRoles, roleBody: ' + JSON.stringify(roleBody, null, 3));
                        const role = await this.authApi.postAuthorizationRoles(roleBody);
                        console.log('Premium addRoles, postAuthorizationRoles done, role: ' + JSON.stringify(role, null, 3));

                        this.logInfo(`Created role "${role.name}"`);

                        console.log('Premium addRoles, putAuthorizationRoleUsersAdd, role id: %s, user id: %s', role.id, user.id);
                        await this.authApi.putAuthorizationRoleUsersAdd(role.id, [user.id]);
                        console.log('Premium addRoles, putAuthorizationRoleUsersAdd done');

                        console.log('Premium addRoles, postAuthorizationSubjectDivisionRole, subjectId: %s, divisionId: *, role id: %s', user.id, role.id);
                        await this.authApi.postAuthorizationSubjectDivisionRole(user.id, '*', role.id, { subjectType: 'PC_USER' });
                        console.log('Premium addRoles, putAuthorizationRoleUsersAdd done');

                        this.logInfo(`Assigned role "${role.name}" to user`);

                        role.type = roleData.type;
                        roles.push(role);
                    }
                    catch (err) {
                        console.log('Premium addRoles, message:', err.body && err.body.message);

                        if (roleData.type === 'server') {
                            throw err;
                        }
                    }
                }
            }

            console.log('Premium addRoles end, roles = %s', roles.length);
            return roles;
        }
        catch (err) {
            console.log('Premium addRoles, error:' + JSON.stringify(err, null, 3));
            throw err;
        }
    }

    //// =======================================================
    ////      OAUTH2 CLIENT
    //// =======================================================

    /**
     * Get existing authetication clients  based on installation data
     * @returns {Promise.<Array>} Array of PureCloud OAuth Clients
     */
    getExistingAuthClients = async () => {
        console.log('Premium getExistingAuthClients begin');

        try {
            // Required Permissions - User making this call must have ANY of these permissions: oauth:client:view
            // Required Scopes - Apps making this call must have one of these scopes: oauth, oauth:readonly
            const entities = await this.getEntities(this.oAuthApi, 'getOauthClients');

            const oauthNames = config.provisioningInfo.oauth.map(o => o.name);
            console.log('Premium getExistingAuthClients, oauthNames: ' + oauthNames);

            console.log('Premium getExistingAuthClients end');
            return entities.filter(entity => oauthNames.includes(entity.name) && entity.state === 'active'); // oauths scheduled for deletion become inactive
        }
        catch (err) {
            console.log('Premium getExistingAuthClients, error:' + JSON.stringify(err, null, 3));
            throw err;
        }
    }

    /**
     * Delete all existing PremiumApp instances
     * @returns {Promise}
     */
    deleteAuthClients = async () => {
        console.log('Premium deleteAuthClients begin');

        try {
            const entities = await this.getExistingAuthClients();
            const promises = [];

            console.log('Premium deleteAuthClients, entities: ' + JSON.stringify(entities, null, 3));

            entities.forEach(entity => {
                promises.push(this.oAuthApi.deleteOauthClient(entity.id))
            });

            console.log('Premium deleteAuthClients end');
            return Promise.all(promises);
        }
        catch (err) {
            console.log('Premium deleteAuthClients, error:' + JSON.stringify(err, null, 3));
            throw err;
        }
    }

    /**
     * Add PureCLoud instances based on installation data
     * @returns {Promise.<Array>} PureCloud OAuth objects
     */
    addAuthClients = async (roles) => {
        console.log('Premium addAuthClients begin');

        try {
            const oauthClients = [];

            // create oauth one by one to show pretty log lines to user
            for (const oauthClientData of config.provisioningInfo.oauth) {
                const oauthClientBody = {
                    name: oauthClientData.name,
                    description: oauthClientData.description,
                    authorizedGrantType: oauthClientData.authorizedGrantType,
                    roleIds: roles.filter(r => r.type === 'server').map(r => r.id),
                    // to be able to do analytics queries in all divisions instead of just default "Home" division
                    roleDivisions: roles.filter(r => r.type === 'server').map(r => ({ roleId: r.id, divisionId: '*' }))
                };

                console.log('Premium addAuthClients, postOauthClients, roleBody: ' + JSON.stringify(oauthClientBody, null, 3));
                const oauthClient = await this.oAuthApi.postOauthClients(oauthClientBody);
                console.log('Premium addAuthClients, postOauthClients done, oauthClient: ' + JSON.stringify(oauthClient, null, 3));

                this.logInfo(`Created OAuth client "${oauthClient.name}"`);
                oauthClients.push(oauthClient);
            }

            return oauthClients;
        }
        catch (err) {
            console.log('Premium addAuthClients, error:' + JSON.stringify(err, null, 3));
            throw err;
        }
    }

    //// =======================================================
    ////      ORGANIZATION
    //// =======================================================

    addOrganization(oauth) {
        console.log('Premium addOrganization begin');

        return new Promise((resolve, reject) => {
            const request = {
                type: 'POST',
                headers: {
                    token: this.accessToken || localStorage.getItem(config.integrationType + ':accessToken'),
                    env: this.environment || localStorage.getItem(config.integrationType + ':environment'),
                    tokensource: 'purecloud'
                },
                url: config.endpoints.api + '/organization',
                contentType: 'application/json',
                dataType: 'json',
                data: JSON.stringify({
                    // currently using only one oauth client
                    clientId: oauth[0].id,
                    clientSecret: oauth[0].secret
                })
            };
            console.log('Premium addOrganization, request: ' + JSON.stringify(request, null, 3));

            $.ajax(request)
                .done((response) => {
                    console.log('Premium addOrganization resolve, response: ' + JSON.stringify(response));
                    return resolve(response);
                })
                .fail((xhr, status, error) => {
                    console.log('Premium addOrganization, xhr: ' + JSON.stringify(xhr, null, 3));
                    console.log('Premium addOrganization, status: ' + JSON.stringify(status, null, 3));
                    console.log('Premium addOrganization, error: ' + JSON.stringify(error, null, 3));

                    const message = (xhr && xhr.responseJSON && xhr.responseJSON.message) || 'Could not add organization';

                    console.log('Premium addOrganization reject, message = ' + message);
                    return reject(new Error(message));
                });
        });
    }


    //// =======================================================
    ////      PROVISIONING / DEPROVISIONING
    //// =======================================================

    /**
     * Delete all existing Premium App PC objects
     * @returns {Promise}
     */
    clearConfigurations = () => {
        console.log('Premium clearConfigurations begin');
        console.log('Premium clearConfigurations end');

        return Promise.all([
            this.deleteAuthClients(),
            this.deleteRoles()
        ]);
    }

    /**
     * Final Step of the installation wizard. 
     * Create the PureCloud objects defined in provisioning configuration
     * The order is important for some of the PureCloud entities.
     */
    installConfigurations = async (setLoadingOverlayText) => {
        console.log('Premium installConfigurations begin');
        this.logInfo = setLoadingOverlayText;

        try {
            await this.clearConfigurations();

            const roles = await this.addRoles();
            const oauth = await this.addAuthClients(roles);
            await this.addOrganization(oauth);

            this.logInfo('Installation Complete!');

            console.log('Premium installConfigurations end');
        }
        catch (err) {
            console.log('Premium installConfigurations, error:' + JSON.stringify(err, null, 3));
            throw err;
        }
    }

    //// =======================================================
    ////      DISPLAY/UTILITY FUNCTIONS
    //// =======================================================

    /**
     * Renders the proper text language into the web pages
     * @param {Object} text  Contains the keys and values from the language file
     */
    displayPageText(text) {
        $(document).ready(() => {
            for (let key in text) {
                if (!text.hasOwnProperty(key)) continue;
                $("." + key).text(text[key]);
            }
        });
    }

    //// =======================================================
    ////      ENTRY POINT
    //// =======================================================

    start = async () => {
        console.log('Premium start begin');

        try {
            await this._setupClientApp();

            const data = await this._pureCloudAuthenticate();
            //console.log('Premium start, data: ' + JSON.stringify(data));

            if (data && data.accessToken) {
                localStorage.setItem(config.integrationType + ':accessToken', data.accessToken);
                this.accessToken = data.accessToken;
            }

            const app = await this.getApplication();
            console.log('Premium start, app: ' + JSON.stringify(app));

            if (app.application) {
                if(!app.application.isPremium){
                    this.isPremium = true;
                    this.isInstalled = false;
                    this.isAvailable = true;
                    this.ELKStatus='UNKNOWN';
                }else{
                    this.isPremium = app.application.isPremium;
                    this.isInstalled = app.application.isInstalled;
                    this.isAvailable = app.application.isAvailable;
                    this.ELKStatus = app.application.ELKStatus
                }
            }else{
                this.isPremium = true;
                this.isInstalled = false;
                this.isAvailable = true;
                this.ELKStatus='UNKNOWN';
            }

            console.log('Premium start end');
        }
        catch (err) {
            console.log('Premium start error: ' + err);
        }
    }

    getStartupParameter = (name) => {
        console.log('Premium getStartupParameter begin');
        console.log('Premium getStartupParameter, name: ' + name);

        const params = new URLSearchParams(window.location.search);

        let value = params.get(name);
        console.log('Premium getStartupParameter, window location %s = %s', name, value);

        // Stores the query parameters into localstorage
        // If query parameters are not provided, try to get values from localstorage
        // Default values if it does not exist.

        const storageKey = config.integrationType + ':' + name;
        console.log('Premium getStartupParameter, storageKey: ' + storageKey);

        const storageItem = localStorage.getItem(storageKey);
        console.log('Premium getStartupParameter, storageItem: ' + storageItem);

        if (value) {
            console.log('Premium getStartupParameter, localStorage setItem %s = %s', storageKey, value);
            localStorage.setItem(storageKey, value);

        } else if (storageItem) {
            value = storageItem;
            console.log('Premium getStartupParameter, use local storage %s = %s', name, value);

        } else {
            // Use default
            value = config['default' + name.charAt(0).toUpperCase() + name.slice(1)]; // fancy way of getting defaultEnvironment / defaultLangTag
            console.log('Premium, getStartupParameter, use default %s = %s', name, value);
        }

        console.log('Premium getStartupParameter end');
        return value;
    }

    /**
     * First thing that needs to be called to setup up the PureCloud Client App
     */
    _setupClientApp = () => {
        console.log('Premium _setupClientApp begin');
        console.log('Premium _setupClientApp, window.location: ' + window.location);

        this.environment = this.getStartupParameter('environment');
        console.log('Premium _setupClientApp, this.environment: %s', this.environment);

        this.language = this.getStartupParameter('langTag');
        console.log('Premium _setupClientApp, this.language: %s', this.language);

        // Get the language context file and assign it to the app
        // For this example, the text is translated on-the-fly.
        return new Promise((resolve, reject) => {
            const fileUri = `languages/wizard/${this.language}.json`;
            $.getJSON(fileUri)
                .done(data => {
                    this.displayPageText(data);
                    console.log('Premium _setupClientApp resolve');
                    return resolve();
                })
                .fail(xhr => {
                    console.log('Premium _setupClientApp reject');
                    return reject(new Error(`Language file not found - "${this.language}.json"`));
                });
        });
    }

    /**
     * Authenticate to PureCloud (Implicit Grant)
     * @return {Promise}
     */
    _pureCloudAuthenticate = () => {
        console.log('Premium _pureCloudAuthenticate, environment: ' + this.environment);
        this.purecloudClient.setEnvironment(this.environment);

        console.log('Premium _pureCloudAuthenticate, clientId: ' + config.clientID);
        console.log('Premium _pureCloudAuthenticate, redirectUri: ' + this.redirectUri);

        return this.purecloudClient.loginImplicitGrant(config.clientID, this.redirectUri);
    }
}