import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { config } from '../config';


const PrivateRoute = ({ component: Component, ...rest }) => {
  // console.log('PrivateRoute, rest = ' + JSON.stringify(rest, null, 3));
  return (
    <Route render={(props) => (
      localStorage.getItem(config.integrationType + ':accessToken')
        ? <Component {...props} {...rest}/>
        : <Redirect to='/' />
    )} />
  )
}

export default PrivateRoute