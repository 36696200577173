import './Header.css';

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import { getProperties } from '../../services/Properties';
import { config } from '../../config';

const Header = ({ app }) => {
    console.log('Header begin');
    console.log('Header, app = ' + app);

    const history = useHistory();

    const [isOpen, setIsOpen] = useState(false);
    const [dashboardVisible, setDashboardVisible] = useState();
    const [properties, setProperties] = useState();

    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        console.log('Header useEffect begin, app = %s', app);

        const init = async () => {
            console.log('Header init begin');

            try {
                const props = await getProperties();
                console.log('Header init, properties = ' + props);

                setProperties(props);

                if (props.dashboards && props.dashboards[0]) {
                    setDashboardVisible(props.dashboards[0].id);
                }

                console.log('Header init end');
            }
            catch (err) {
                console.log('Header init, error = ' + err);
            }
        }

        init();

        console.log('Header useEffect end');

    }, [app]);

    console.log('Header end');
    return (
        <div>
            <Navbar style={{ backgroundColor: "#856685" }} dark expand="md">
                <NavbarBrand>{config.appName}</NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        {app && app.accessToken && properties && properties.dashboards && properties.dashboards.length > 0 && (
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>Metrics</DropdownToggle>
                                <DropdownMenu right>
                                    {properties.dashboards.map(dashboard => (
                                        <DropdownItem key={dashboard.name} active={dashboardVisible === dashboard.id}>
                                            <div onClick={() => { history.push('/home/' + dashboard.id); setDashboardVisible(dashboard.id) }}>{dashboard.name}</div>
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        )}
                        {app && app.accessToken && properties && properties.dashboards && properties.dashboards.length > 0 && (
                            <NavItem>
                                <NavLink href="#" onClick={() => history.push('/settings')}>Settings</NavLink>
                            </NavItem>
                        )}
                        {app && app.accessToken && properties && properties.dashboards && properties.dashboards.length > 0 && (
                            <NavItem>
                                <NavLink href="#" onClick={() => history.push('/help')}>
                                    <FontAwesomeIcon icon={faQuestionCircle} className="enlarge-medium" />
                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                </Collapse>
            </Navbar>
        </div>
    );
}

export default Header;