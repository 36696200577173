import './Home.css'

import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';

import { Container, Spinner } from 'reactstrap';

import { getProperties } from '../../services/Properties';
import AuthIFrame from "../iframeAuth/AuthIFrame";


const Home = (props) => {
    console.log('Home begin');
    //console.log('Home, props = ' + JSON.stringify(props));

    //const { dashboardId } = useParams(); // not working for now
    const dashboardId = props && props.computedMatch && props.computedMatch.params && props.computedMatch.params.dashboardId;
    console.log('Home, dashboardId = ' + dashboardId);

    const [dashboardVisible, setDashboardVisible] = useState(dashboardId);
    const [properties, setProperties] = useState();

    console.log('Home, dashboardVisible = ' + dashboardVisible);
    if (dashboardId && dashboardId !== dashboardVisible) {
        setDashboardVisible(dashboardId);
    }

    const options = {
        method: 'get',
        headers: new Headers({'Authorization': 'Bearer n/XoAxZSZEh0a3I3S1ExLTlEWW94Tk1mdjlBAAAAAAAAAAAA'}),
        mode: 'no-cors',
        "Content-Type":'text/html; charset=utf-8',
        "User-Agent" : "Mozilla/5.0"
    };

    //options.body = JSON.stringify(body);
    console.log('Home, :::::::::::::::::::::::::::::::Trying to getch Kibana url with a API Key::::::::::::::::::::::::: ');
    let kibanaContent =  fetch('https://genpstest-gcm-us-east-1.kb.us-east-1.aws.found.io:9243/app/dashboards#/view/370e9d50-9b6c-11eb-919d-753fa96fadc2?_g=(filters:!())', options);
    console.log(kibanaContent)
    console.log('Type of Kibana call'+typeof(kibanaContent));

    fetch('https://genpstest-gcm-us-east-1.kb.us-east-1.aws.found.io:9243/app/dashboards#/view/370e9d50-9b6c-11eb-919d-753fa96fadc2?_g=(filters:!())', options).then(response => {
        console.log(':::::::::::::::::::::::::GOt response from KIbana Async call');    
        console.log(response);
        console.log(response.ok);
    });
  

    /***
     * 
     *
     * function populateIframe(iframe, url, headers) {
                                let xhr = new XMLHttpRequest();
                                xhr.open('GET', url);
                                xhr.onreadystatechange = handler;
                                xhr.responseType = 'blob';
                                    headers.forEach(function(header) {
                                        xhr.setRequestHeader(header[0], header[1]);
                                    });
                                xhr.send();

                                    function handler() {
                                        if (this.readyState === this.DONE) {
                                            if (this.status === 200) {
                                            // iframe.src = URL.createObjectURL(this.response);
                                            } else {
                                                console.error('Request failed', this);
                                            }
                                        }
                                    }
                        }
     */
    useEffect(() => {
        console.log('Home useEffect begin');

        const init = async () => {
            try {
                console.log('Home, init');
                const responses = await Promise.all([getProperties()]);

                //console.log('Home, init responses = ' + JSON.stringify(responses, null, 3));

                if (responses[0]) {
                    setProperties(responses[0]);

                    if (responses[0].dashboards && responses[0].dashboards[0]) {
                        setDashboardVisible(responses[0].dashboards[0].id);
                    }
                }

            }
            catch (err) {
                console.log('Home, init err = ' + err);
            }
        }

        init();

        console.log('Home useEffect end');

    }, []);


    console.log('Home end');
    //<AuthIFrame    title={dashboard.name}    src={dashboard.url}  token='n/XoAxZSZEh0a3I3S1ExLTlEWW94Tk1mdjlBAAAAAAAAAAAA' className="kibana-iframe"  type = 'application/html'/>
    //
    return (
        <div>
            <script>
                populateIframe('https://genpstest-gcm-us-east-1.kb.us-east-1.aws.found.io:9243/', [['x-api-version', 'v1.2']]);
            </script>
            <Container className="p-0" fluid>
                {!properties && (
                    <div className="spinner-container">
                        <Spinner color="secondary" />
                    </div>
                )}
                {properties && properties.dashboards && properties.dashboards.map(dashboard => (
                    <div key={"kibana-wrapper-" + dashboard.id} style={{ display: dashboardVisible === dashboard.id ? 'block' : 'none' }} >
                        <h5 className="home-heading">{dashboard.name}</h5>
                        
                        <iframe title={dashboard.name} className="kibana-iframe" src={dashboard.url}></iframe>
                        
                    </div>
                ))}
            </Container>
        </div>
    );

}

function handler() {
    if (this.readyState === this.DONE) {
      if (this.status === 200) {
        // this.response is a Blob, because we set responseType above
        console.log('Done')
        var data_url = URL.createObjectURL(this.response);
        //document.querySelector('#output-frame-id').src = data_url;
      } else {
        console.error('no pdf :(');
      }
    }
  }

export default Home;