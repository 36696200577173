export const getParameterByName = (name) => {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]")
    const regex = new RegExp("[\\#&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.hash)
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const getRedirectUri = () => `${window.location.protocol}//${window.location.host}`

export default {
    getParameterByName,
    getRedirectUri
}