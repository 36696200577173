// https://www.digitalocean.com/community/tutorials/how-to-build-forms-in-react
// https://www.digitalocean.com/community/tutorials/how-to-call-web-apis-with-the-useeffect-hook-in-react
// https://jasonwatmore.com/post/2020/10/09/react-crud-example-with-react-hook-form

import './Settings.css'

import React, { useEffect, useState } from 'react';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Spinner } from 'reactstrap';

import { getProperties, setProperties } from '../../services/Properties';

const Settings = () => {
    const [alert, setAlert] = useState();
    const [settingsLoaded, setSettingsLoaded] = useState(false);

    // form validation rules 
    const emailSchema = Yup.object().shape({
        email: Yup.string().required().email()
    });

    const urlSchema = Yup.object().shape({
        url: Yup.string().required().url()
    });

    const validationSchema = Yup.object().shape({
        // ALERTS
        'alertsEnabled': Yup.boolean(),

        'alertsWarningIntervalMinutes': Yup.number()
            .min(1, 'Warning Interval Minutes must be 1 or greater')
            .required('Warning Interval Minutes is required'),

        'alertsCriticalIntervalMinutes': Yup.number()
            .min(1, 'Critical Interval Minutes must be 1 or greater')
            .required('Critical Interval Minutes is required'),

        'alertsEmailRecipients': Yup.string()
            .test('email-list', 'Email recipient list is not valid.',
                function (alertsEmailRecipients) {
                    console.log('Settings, alertsEmailRecipients = %s', alertsEmailRecipients);

                    if (alertsEmailRecipients) {
                        // split recipient list at comma, trim each value and validate against email schema
                        const alertsEmailRecipientArray = alertsEmailRecipients.split(',').map(email => email.replace(/^\s+|\s+$/gm, ''));
                        console.log('Settings, alertsEmailRecipientArray = %s', JSON.stringify(alertsEmailRecipientArray));

                        for (const email of alertsEmailRecipientArray) {
                            const valid = emailSchema.isValidSync({ email });
                            console.log('Settings, email = %s, valid = %s', email, valid);
                            if (!valid) {
                                return false;
                            }
                        }
                    }

                    return true;
                }),

        // EDGES
        // not online edge critical alert always active

        'edgesWarningPercentage': Yup.number()
            .min(0, 'Warning Percentage must be between 0 and 100')
            .max(100, 'Warning Percentage must be between 0 and 100')
            .lessThan(Yup.ref('edgesCriticalPercentage'), 'Warning Percentage must be less than Critical Percentage')
            .required('Warning Percentage is required'),

        'edgesCriticalPercentage': Yup.number()
            .min(0, 'Critical Percentage must be between 0 and 100')
            .max(100, 'Critical Percentage must be between 0 and 100')
            .moreThan(Yup.ref('edgesWarningPercentage'), 'Critical Percentage must be greater than Warning Percentage')
            .required('Critical Percentage is required'),

        // TRUNKS
        // not online trunk critical alert always active

        // PHONES
        'phonesNotPrivisionedMinutes': Yup.number()
            .min(0, 'Not Provisioned Minutes must be 0 or greater')
            .required('Not Provisioned Minutes is required'),

        // AGENTS
        'agentsNotResponingMinutes': Yup.number()
            .min(0, 'Not Responing Minutes must be 0 or greater')
            .required('Not Responing Minutes is required'),

        // CONVERSATIONS
        'conversationsDurationMinutes': Yup.number()
            .min(0, 'Long Conversation Minutes must be 0 or greater')
            .required('Long Conversation Minutes is required'),

        // WEBSITES
        'websitesRequestTimeoutMilliseconds': Yup.number()
            .min(0, 'Request Timeout Milliseconds must be 0 or greater')
            .required('Request Timeout Milliseconds'),

        'websitesUrls': Yup.string()
            .test('url-list', 'Url list is not valid.',
                function (websitesUrls) {
                    console.log('Settings, websitesUrls = %s', websitesUrls);

                    if (websitesUrls) {
                        const websitesUrlsArray = websitesUrls.split(',').map(url => url.replace(/^\s+|\s+$/gm, ''));
                        console.log('Settings, websitesUrlsArray = %s', JSON.stringify(websitesUrlsArray));

                        for (const url of websitesUrlsArray) {
                            const valid = urlSchema.isValidSync({ url });
                            console.log('Settings, url = %s, valid = %s', url, valid);
                            if (!valid) {
                                return false;
                            }
                        }
                    }

                    return true;
                })

    });

    // functions to build form returned by useForm() hook
    const { register, handleSubmit, reset, setValue, errors, formState } = useForm({
        resolver: yupResolver(validationSchema)
    });

    function onSubmit(data) {
        console.log('onSubmit, data = %s', JSON.stringify(data, null, 3));

        return setProperties(data)
            .then(response => {
                console.log('Settings, setProperties response = %s', JSON.stringify(response, null, 3));

                // set "new" values to reset "isDirty" status
                reset(data);

                setAlert({
                    type: 'alert-success',
                    message: 'Settings saved succesfully.'
                });

                setTimeout(() => {
                    setAlert();
                }, 5000);
            })
            .catch(error => {
                console.log('Settings, setProperties error = %s', error);

                setAlert({
                    type: 'alert-danger',
                    message: 'Settings not saved: ' + error
                });

                setTimeout(() => {
                    setAlert();
                }, 5000);
            });
    }

    useEffect(() => {
        const init = async () => {
            console.log('Settings init begin');

            try {
                const properties = await getProperties();
                console.log('Settings init, properties = ' + properties);

                // initialize form data
                const keyValuePairSections = ['alerts', 'edges', 'phones', 'agents', 'conversations', 'websites'];
                let keyValuePairs = [];

                keyValuePairSections.forEach(section => {
                    console.log('Settings init, section = %s', section);
                    if (properties && properties[section]) {
                        keyValuePairs = [...keyValuePairs, ...Object.entries(properties[section])];
                    }

                    keyValuePairs.forEach(entry => {
                        const [key, value] = entry;

                        console.log('Settings init, %s = %s', key, value);
                        setValue(key, value);
                    });
                });

                setSettingsLoaded(true);

                console.log('Settings init end');
            }
            catch (err) {
                console.log('Settings init, error = ' + err);
            }
        }

        init();

    }, [setValue]);

    return (
        <div className="container-fluid p-0">
            <div className="settings-iframe">
                {!settingsLoaded && (
                    <div className="spinner-container">
                        <Spinner color="secondary" />
                    </div>
                )}

                <div className="row" style={{ display: (settingsLoaded ? 'block' : 'none') }}>
                    <div className="col-md-3">

                        <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                            <h5 className="settings-heading">Alerts</h5>
                            <fieldset disabled={formState.isSubmitting}>
                                <div className="form-group form-check">
                                    <label className="form-check-label">
                                        <input type="checkbox"
                                            name="alertsEnabled"
                                            className={`form-check-input`}
                                            ref={register} />Enabled
                                    </label>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="alertsWarningIntervalMinutes">Warning Interval Minutes</label>
                                    <input type="text"
                                        name="alertsWarningIntervalMinutes"
                                        className={`form-control ${errors.alertsWarningIntervalMinutes ? 'is-invalid' : ''}`}
                                        ref={register}
                                        placeholder="360" />
                                    <div className="invalid-feedback">{errors.alertsWarningIntervalMinutes?.message}</div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="alertsCriticalIntervalMinutes">Critical Interval Minutes</label>
                                    <input type="text"
                                        name="alertsCriticalIntervalMinutes"
                                        className={`form-control ${errors.alertsCriticalIntervalMinutes ? 'is-invalid' : ''}`}
                                        ref={register}
                                        placeholder="2" />
                                    <div className="invalid-feedback">{errors.alertsCriticalIntervalMinutes?.message}</div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="alertsEmailRecipients">Email Recipients</label>
                                    <textarea
                                        name="alertsEmailRecipients"
                                        className={`form-control ${errors.alertsEmailRecipients ? 'is-invalid' : ''}`}
                                        ref={register}
                                        rows="4"
                                        placeholder="recipient@example.com, recipient2@example.com" />
                                    <div className="invalid-feedback">{errors.alertsEmailRecipients?.message}</div>
                                </div>
                            </fieldset>

                            <h5 className="settings-heading">Edges</h5>
                            <fieldset disabled={formState.isSubmitting}>
                                <div className="form-group">
                                    <label htmlFor="edgesWarningPercentage">Warning Percentage</label>
                                    <input type="text"
                                        name="edgesWarningPercentage"
                                        className={`form-control ${errors.edgesWarningPercentage ? 'is-invalid' : ''}`}
                                        ref={register}
                                        placeholder="80" />
                                    <div className="invalid-feedback">{errors.edgesWarningPercentage?.message}</div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="edgesCriticalPercentage">Critical Percentage</label>
                                    <input type="text"
                                        name="edgesCriticalPercentage"
                                        className={`form-control ${errors.edgesCriticalPercentage ? 'is-invalid' : ''}`}
                                        ref={register}
                                        placeholder="90" />
                                    <div className="invalid-feedback">{errors.edgesCriticalPercentage?.message}</div>
                                </div>
                            </fieldset>

                            <h5 className="settings-heading">Phones</h5>
                            <fieldset disabled={formState.isSubmitting}>
                                <div className="form-group">
                                    <label htmlFor="phonesNotPrivisionedMinutes">Not Provisioned Minutes</label>
                                    <input type="text"
                                        name="phonesNotPrivisionedMinutes"
                                        className={`form-control ${errors.phonesNotPrivisionedMinutes ? 'is-invalid' : ''}`}
                                        ref={register}
                                        placeholder="1440" />
                                    <div className="invalid-feedback">{errors.phonesNotPrivisionedMinutes?.message}</div>
                                </div>
                            </fieldset>

                            <h5 className="settings-heading">Agents</h5>
                            <fieldset disabled={formState.isSubmitting}>
                                <div className="form-group">
                                    <label htmlFor="agentsNotResponingMinutes">Not Responding Minutes</label>
                                    <input type="text"
                                        name="agentsNotResponingMinutes"
                                        className={`form-control ${errors.agentsNotResponingMinutes ? 'is-invalid' : ''}`}
                                        ref={register}
                                        placeholder="1" />
                                    <div className="invalid-feedback">{errors.agentsNotResponingMinutes?.message}</div>
                                </div>
                            </fieldset>

                            <h5 className="settings-heading">Conversations</h5>
                            <fieldset disabled={formState.isSubmitting}>
                                <div className="form-group">
                                    <label htmlFor="conversationsDurationMinutes">Long Conversation Minutes</label>
                                    <input type="text"
                                        name="conversationsDurationMinutes"
                                        className={`form-control ${errors.conversationsDurationMinutes ? 'is-invalid' : ''}`}
                                        ref={register}
                                        placeholder="60" />
                                    <div className="invalid-feedback">{errors.conversationsDurationMinutes?.message}</div>
                                </div>
                            </fieldset>

                            <h5 className="settings-heading">Websites</h5>
                            <fieldset disabled={formState.isSubmitting}>
                                <div className="form-group">
                                    <label htmlFor="websitesRequestTimeoutMilliseconds">Request Timeout Milliseconds</label>
                                    <input type="text"
                                        name="websitesRequestTimeoutMilliseconds"
                                        className={`form-control ${errors.websitesRequestTimeoutMilliseconds ? 'is-invalid' : ''}`}
                                        ref={register}
                                        placeholder="10000" />
                                    <div className="invalid-feedback">{errors.websitesRequestTimeoutMilliseconds?.message}</div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="websitesUrls">Website Urls</label>
                                    <textarea
                                        name="websitesUrls"
                                        className={`form-control ${errors.websitesUrls ? 'is-invalid' : ''}`}
                                        ref={register}
                                        rows="4"
                                        placeholder="https://login.mypurecloud.com, https://apps.mypurecloud.com" />
                                    <div className="invalid-feedback">{errors.websitesUrls?.message}</div>
                                </div>
                            </fieldset>

                            <div className="form-group">
                                {alert && (
                                    <div className={`alert ${alert.type}`}>
                                        {alert.message}
                                    </div>
                                )}
                                < button type="submit" disabled={!formState.isDirty || formState.isSubmitting} className="btn btn-secondary float-right">
                                    {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Save
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div >
    );

}

export default Settings;