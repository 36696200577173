import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Container, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { config } from '../config';
import Premium from '../scripts/premium';

const Main = (props) => {
    console.log('Main begin');
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [productAvailable, setProductAvailable] = useState();

    const app = new Premium();

    const init = async () => {
        try {
            // get language and environment, login, get application properties
            await app.start();

            console.log(':::::::::::::::Main, isAvailable = %s, isInstalled = %s, isPremium = %s app.ELKStatus= %s', app.isAvailable, app.isInstalled, app.isPremium,app.ELKStatus);

            if (app.isAvailable) {
                console.log('ELK Status is ',app.ELKStatus);
                if (!app.isInstalled) {
                    console.log('redirecting to wizard start');
                    history.push('/wizard/start');
                }else if(app.ELKStatus ==='ELB-INSTALLED'|| app.ELKStatus ==='NOT_PROVISIONED'||app.ELKStatus ==='ELBREADY'){
                    console.log('redirecting to please wait page');
                    history.push('/static/elkstarting');
                }else if(app.ELKStatus ==='MAINTANENCE' ||app.ELKStatus ==='UNKNOWN'){
                    console.log('redirecting to please wait page');
                    history.push('/static/maintanence');
                }
                // trigger state change in AppRouter
                console.log('Main, set app');
                props.setApp(app);
            }
            else {
                setLoading(false);
                setProductAvailable(false);
            }

            console.log('Main end');
        }
        catch (err) {
            console.log('Main error = ' + err);
        }
    }

    init();

    return (
        <div>
            <Container className="p-0" fluid>
                {loading && (
                    <div className="spinner-container">
                        <Spinner color="secondary" />
                    </div>
                )}

                {!loading && (
                    <div>
                        <main>
                            <div>
                                <div/>
                                {!productAvailable && (
                                    <div>
                                        <p className="fail">
                                            <span className="txt-product-not-available">Product not available</span>&nbsp;
                                                <FontAwesomeIcon icon={faTimesCircle} className="enlarge" />
                                        </p>
                                        <p>
                                            <span className="txt-not-available-message-x">
                                                We're sorry but your Genesys Cloud organization does not have the {config.appName} product enabled.
                                                    Please contact the authorities.
                                                </span>
                                        </p>
                                    </div>
                                )}
                            </div>
                        </main>
                    </div>
                )}
            </Container>
        </div>
    );
}

export default Main;

