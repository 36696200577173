import './Help.css';

import React from 'react';

const Help = () => {
    return (
        <div className="container-fluid p-0">
            <div className="help-container">
                <div className="row">
                    <div className="col-lg-8">
                        <div id="overview">
                            <h1>Overview of Genesys Cloud Monitoring</h1>
                            <p>Genesys Cloud Monitoring is the tool to monitor Genesys Cloud health. Contact Center administrators, network operations centers (NOCs), and other users can use informative graphics of Genesys Cloud organization.</p>
                            <p>Graphs are organized within 8 dashboards: Agents, Calls, Conversations, Network, Phones, Resources, Trunks, and Websites. Genesys Cloud health is queried every 60 seconds.</p>
                            <p>Alerts can be set up to be notified of any issues to allow a quick and immediate response.</p>
                        </div>

                        <div id="metrics">
                            <h1>Metrics</h1>
                            <p>Genesys Cloud Monitoring organizes data within 8 dashboards which can be accessed under the Metrics menu &ndash;</p>
                            <ul>
                                <li>Agents</li>
                                <li>Calls</li>
                                <li>Conversations</li>
                                <li>Network</li>
                                <li>Phones</li>
                                <li>Resources</li>
                                <li>Trunks</li>
                                <li>Websites</li>
                            </ul>

                            <p>For each dashboard, you can define a date range to view the graphical data. There are pre-defined commonly used date ranges available along with recently used date ranges.</p>
                            <figure>
                                <img src="images/help/common_date_ranges.png" style={{ maxWidth: '450px' }} />
                                <figcaption>Common date ranges</figcaption>
                            </figure>

                            <p>When clicking on "Show dates" it is possible to set a custom absolute or relative date range.</p>
                            <p>Setting custom absolute monitoring date range &ndash;</p>
                            <figure>
                                <img src="images/help/absolute_date_range.png" style={{ maxWidth: '460px' }} />
                                <figcaption>Absolute date range</figcaption>
                            </figure>

                            <p>Setting custom relative monitoring date range &ndash;</p>
                            <figure>
                                <img src="images/help/relative_date_range.png" style={{ maxWidth: '450px' }} />
                                <figcaption>Relative date range</figcaption>
                            </figure>

                            <div id="metrics-agents">
                                <h2>Agents</h2>
                                <p>"Agents" dashboard shows information about agents in "Not Responding" status for longer than a specific amount of time which can be configured in the "Settings" page by specifying the "Not Responding Minutes" value (default is 1 minute).</p>
                                <figure>
                                    <img src="images/help/agents.png" />
                                    <figcaption>Agents</figcaption>
                                </figure>

                                <h3>Agents Not Responding</h3>
                                <p>"Agents Not Responding" chart shows historical information about the Top 5 agents in "Not Responding" status in the selected date range. If there are more than 5 Agents in the "Not Responding" status those are grouped and can be expanded under the "Other" label.</p>

                                <h3>Current Agents Not Responding List</h3>
                                <p>"Current Agents Not Responding List" table shows an alphabetical list of Top 30 agents currently in "Not Responding" status.</p>
                            </div>
                            <div id="metrics-calls">
                                <h2>Calls</h2>
                                <p>"Calls" dashboard shows information about ongoing calls at discrete moments in time which correspond to 1-minute data refresh intervals from Genesys Cloud.</p>
                                <figure>
                                    <img src="images/help/calls.png" />
                                    <figcaption>Calls</figcaption>
                                </figure>

                                <h3>Inbound Calls</h3>
                                <p>"Inbound Calls" chart shows historical information about maximum and average inbound call counts in the selected date range. Maximum and average values are calculated in time buckets displayed below the chart (e.g "@timestamp per 3 hours"). Time bucket length is automatically calculated depending on the selected date range. You can "drill down" by selecting a smaller time range on the chart.</p>

                                <h3>Outbound Calls</h3>
                                <p>"Outbound Calls" chart shows historical information about maximum and average outbound call counts per time bucket in the selected date range.</p>

                                <h3>Maximum Concurrent Calls</h3>
                                <p>"Maximum Concurrent Calls" metric shows the maximum number of inbound and outbound calls in the selected date range.</p>

                                <h3>Inbound Calls By Trunk</h3>
                                <p>"Inbound Calls By Trunk" chart shows historical information about maximum and average inbound call counts per time bucket in the selected date range grouped by Trunk base.</p>

                                <h3>Outbound Calls By Trunk</h3>
                                <p>"Outbound Calls By Trunk" chart shows historical information about maximum and average outbound call counts per time bucket in the selected date range grouped by Trunk base.</p>

                            </div>
                            <div id="metrics-conversations">
                                <h2>Conversations</h2>
                                <p>"Conversations" dashboard shows information about conversations (interactions) which last longer than a specific amount of time which can be configured in the "Settings" page by specifying the "Long Conversation Minutes" value (default is 60 minutes).</p>
                                <figure>
                                    <img src="images/help/conversations.png" />
                                    <figcaption>Conversations</figcaption>
                                </figure>

                                <h3>Long Conversations</h3>
                                <p>"Long Conversations" chart shows historical information about Top 5 long conversations in the selected date range. If there are more than 5 long Conversations those are grouped and can be expanded under the "Other" label.</p>

                                <h3>Current Long Conversations List</h3>
                                <p>The "Current Long Conversations List" table shows the list of current long conversations in minutes. Additionally, conversation originating direction, media type, and the queue is displayed if available and can be used for ordering and filtering table data.</p>
                            </div>
                            <div id="metrics-network">
                                <h2>Network</h2>
                                <p>"Network" dashboard shows information about edge network usage.</p>
                                <figure>
                                    <img src="images/help/network.png" />
                                    <figcaption>Network</figcaption>
                                </figure>

                                <h3>Received Bytes</h3>
                                <p>"Received Bytes" chart shows historical information about average received byte count per time bucket in the selected date range grouped by Edge.</p>

                                <h3>Sent Bytes</h3>
                                <p>"Sent Bytes" chart shows historical information about average sent byte count per time bucket in the selected date range grouped by Edge.</p>
                            </div>
                            <div id="metrics-phones">
                                <h2>Phones</h2>
                                <p>"Phones" dashboard shows information about operational phone count and lists operational phones not provisioned specific amount of time which can be configured in "Settings" page by specifying the "Not Provisioned Minutes" value (default is 1440 minutes)</p>
                                <figure>
                                    <img src="images/help/phones.png" />
                                    <figcaption>Phones</figcaption>
                                </figure>

                                <h3>Operational Phones By Edge</h3>
                                <p>"Operational Phones By Edge" chart shows historical information about phone count in the selected date range grouped by Edge.</p>

                                <h3>Operational Phones Count</h3>
                                <p>"Operational Phones Count" chart shows phone count in the selected date range.</p>

                                <h3>Operational Phones List</h3>
                                <p>"Operational Phones List" table shows an alphabetical list of phones.</p>

                                <h3>Operational Phones Not Provisioned</h3>
                                <p>"Operational Phones Not Provisioned" chart shows historical information about Top 5 longest not provisioned operational phones in the selected date range.</p>

                                <h3>Operational Phones Not Provisioned List</h3>
                                <p>"Operational Phones Not Provisioned List" table shows the current list of operational phones provisioned more than a specific amount of time ago.</p>
                            </div>
                            <div id="metrics-resources">
                                <h2>Resources</h2>
                                <p>"Resources" dashboard shows information about available edge Disk, Memory, and CPU resources.</p>
                                <figure>
                                    <img src="images/help/resources.png" />
                                    <figcaption>Resources</figcaption>
                                </figure>

                                <h3>Disk</h3>
                                <p>"Disk" chart shows historical information about available disk space in the selected date range grouped by Edge.</p>

                                <h3>Memory</h3>
                                <p>"Memory" chart shows historical information about available memory in the selected date range grouped by Edge.</p>

                                <h3>CPU</h3>
                                <p>"CPU" chart shows average CPU load percentage in the selected date range grouped by Edge.</p>
                            </div>
                            <div id="metrics-trunks">
                                <h2>Trunks</h2>
                                <p>"Trunks" dashboard shows information about trunks and trunk connection statuses.</p>
                                <figure>
                                    <img src="images/help/trunks.png" />
                                    <figcaption>Trunks</figcaption>
                                </figure>

                                <h3>Trunks By Status</h3>
                                <p>"Trunks By Status" chart shows the percentage of Connected / Disconnected trunks in the selected date range.</p>

                                <h3>Trunks</h3>
                                <p>"Trunks" chart shows historical information about Connected trunk count in the selected monitoring date range.</p>

                                <h3>Trunks List</h3>
                                <p>"Trunks List" table shows the trunk list and current connection status of each trunk in the selected date range.</p>
                            </div>
                            <div id="metrics-websites">
                                <h2>Websites</h2>
                                <p>"Websites" dashboard shows information about website ping response time and status. Websites can be configured in the "Settings" page by entering "Website Urls" and "Request Timeout Milliseconds" (default is 10000 milliseconds)</p>
                                <figure>
                                    <img src="images/help/websites.png" />
                                    <figcaption>Websites</figcaption>
                                </figure>

                                <h3>Website Response Time</h3>
                                <p>"Website Response Time" chart shows historical information about response time in the selected date range for each configured website.</p>

                                <h3>Website Response Percentage</h3>
                                <p>"Website Response Percentage" chart shows the percentage of each website response in the selected date range.</p>

                                <h3>Website Response List</h3>
                                <p>"Website Response List" table shows the list of current website responses, statuses, and response times in milliseconds.</p>
                            </div>

                        </div>

                        <div id="settings">
                            <h1>Settings</h1>
                            <p>The settings page specifies parameters for how data is collected for dashboards and controls how Warning and Critical Alert emails are generated.</p>
                            <figure>
                                <img src="images/help/settings.png" />
                                <figcaption>Settings</figcaption>
                            </figure>

                            <div id="settings-alerts">
                                <h2>Alerts</h2>
                                <p>You can enable Alert email generation when certain conditions are met.<br />
                                Critical Alerts are generated for Edges and Trunks. Warning Alerts are generated for Edges, Trunks, Phones, Agents, Conversations and Websites.</p>
                                <p>When alerts are enabled you will receive en email message with a list of entities and conditions which triggered alerts &ndash; </p>
                                <figure>
                                    <img src="images/help/alert_email.png" />
                                    <figcaption>Alert email</figcaption>
                                </figure>

                                <h3>Warning Interval Minutes</h3>
                                <p>"Warning Interval Minutes" specifies how often warning conditions are checked and emails generated. Default is 360 minutes.</p>

                                <h3>Critical Interval Minutes</h3>
                                <p>"Critical Interval Minutes" specifies how often critical conditions are checked and emails generated. Default is 2 minutes.</p>

                                <h3>Email Recipients</h3>
                                <p>"Email Recipients" specifies comma-separated list of alert email recipients.</p>
                            </div>

                            <div id="settings-edges">
                                <h2>Edges</h2>
                                <p>Warning or Critical Alert emails are generated when the Edge resource (CPU, memory, disk, network) usage percentage crosses threshold values. Critical Alerts are generated for offline Edges.</p>
                                <h3>Warning Percentage</h3>
                                <p>"Warning Percentage" specifies the threshold for Warning Alert email generation. Default is 80.<br />
                                Must be less than "Critical Percentage"</p>

                                <h3>Critical Percentage</h3>
                                <p>"Critical Percentage" specifies the threshold for Critical Alert email generation. Default is 90.<br />
                                Must be greater than "Warning Percentage"</p>
                            </div>

                            <div id="settings-edges">
                                <h2>Trunks</h2>
                                <p>Critical Alerts are generated for offline Trunks.</p>
                            </div>

                            <div id="settings-phones">
                                <h2>Phones</h2>
                                <p>Warning Alerts are generated for non-operational Phones.</p>
                                <h3>Not Provisioned Minutes</h3>
                                <p>"Not Provisioned Minutes" specifies the threshold for operational Phone to be considered not provisioned. Default is 1440.<br />
                                This setting affects data in the "Phones" dashboard and Warning Alert email generation.</p>
                            </div>

                            <div id="settings-agents">
                                <h2>Agents</h2>
                                <h3>Not Responding Minutes</h3>
                                <p>"Not Responding Minutes" specifies the threshold for Agent in not responding status. Default is 1.<br />
                                This setting affects data in the "Agents" dashboard and Warning Alert email generation.</p>
                            </div>

                            <div id="settings-conversations">
                                <h2>Conversations</h2>
                                <h3>Long Conversation Minutes</h3>
                                <p>"Long Conversation Minutes" specifies the threshold for non-finished Conversation (Interaction) since the start. Default is 60.<br />
                                This setting affects data in the "Conversations" dashboard and Warning Alert email generation.</p>
                            </div>

                            <div id="settings-websites">
                                <h2>Websites</h2>
                                <h3>Request Timeout Milliseconds</h3>
                                <p>"Request Timeout Milliseconds" specifies ping timeout for the Website to be considered non-responsive. </p>

                                <h3>Website Urls</h3>
                                <p>"Website Urls" specifies a comma-separated list of Websites to check for responsivenes.<br />
                                This setting affects data in the "Websites" dashboard and Warning Alert email generation.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Help;