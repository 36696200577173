import './static.css'

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faCheckCircle, faChevronRight, faTimesCircle, faExclamationCircle , faServer } from '@fortawesome/free-solid-svg-icons';
import { config } from '../../config';

const Maintanence = ({ app }) => {
    console.log('Maintanence begin');
    console.log('Maintanence, app = ' + app);
   
    //const history = useHistory();
    const location = useLocation();

    const [error] = useState(location.state && location.state.error);

    console.log('Maintanence end');

    return (
        <div>
        <Container className="p-0" fluid>
            <div>
                
                <main>
                        <div className="fail wait-title">
                        <FontAwesomeIcon icon={faExclamationCircle} className="enlarge" />
                                        <span className="txt-not-available-message">   {config.appName} dashboards are under maintanence. </span>
                        </div>
                        
                        <p>
                            <div className='wait-content'>
                                                <span className="txt-not-available-message">
                                                Please contact Support for more details
                                                </span>
                                                </div>
                                            </p>
                                            
                </main>
            </div>
        </Container>
    </div>
    )
}
export default Maintanence