import './Wizard.css'

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { config } from '../../config';

const Summary = ({ app }) => {
    console.log('Summary begin');
    console.log('Summary, app = ' + app);

    //const history = useHistory();
    const location = useLocation();

    const [error] = useState(location.state && location.state.error);

    const open = async () => {
        //history.push('/home');
        window.location = '/'; // re-read everything, will optimize sometime
    };

    console.log('Summary end');

    return (
        <div>
            <Container className="p-0" fluid>
                <div>
                    <div className="title">
                        Done
                    </div>

                    <ul className="progress-bar">
                        <li className="active">
                            <span className="txt-start">Start</span>
                        </li>
                        <li className="active">
                            <span className="txt-install">Install</span>
                        </li>
                        <li className="active current">
                            <span className="txt-summary">Summary</span>
                        </li>
                    </ul>

                    <main>
                        {!error && (
                            <div>
                                <p className="success">
                                    <span className="txt-product-installed">We have created elastic server and OAuth Credentials.</span>&nbsp;
                                    <FontAwesomeIcon icon={faCheckCircle} className="enlarge" />
                                </p>
                                <p className="sink-more">
                                    <span className="txt-finish-install">
                                        Your Installation Request for {config.appName}  is submitted to support.
                                </span>
                                </p>
                                <button className="btn-info right" onClick={open}>
                                    <span className="txt-open">Open</span>
                                </button>
                            </div>
                        )}

                        {error && (
                            <div>
                                <p className="fail">
                                    <span className="txt-product-error">Product could not be installed</span>&nbsp;
                                    <FontAwesomeIcon icon={faExclamationCircle} className="enlarge" />
                                </p>
                                <p>
                                    <span className="txt-error-message">{error}</span>
                                </p>
                            </div>
                        )}

                    </main>
                </div>
            </Container>
        </div>
    )
}
export default Summary