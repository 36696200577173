import './Wizard.css'

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Container, Spinner } from 'reactstrap';

const Install = ({ app }) => {
    console.log('Install begin');
    console.log('Install, app = ' + app);

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [loadingOverlayText, setLoadingOverlayText] = useState();

    const install = async () => {
        try {
            setLoading(true);

            if (!app) {
                throw new Error('Could not install application.');
            }

            await app.installConfigurations(setLoadingOverlayText);
            done();

        }
        catch (err) {
            done(err);
        }
    };

    const done = (err) => {
        setTimeout(() => {
            setLoading(false);

            // https://stackoverflow.com/a/60256650

            history.push({
                pathname: '/wizard/summary',
                state: { error: err && (err.body && err.body.message || err.toString()) }
            });

        }, 3000);
    }

    console.log('Install end');

    return (
        <div>
            <Container className="p-0" fluid>
                <div>
                    <div className="title">
                        Installation
                    </div>

                    <ul className="progress-bar">
                        <li className="active">
                            <span className="txt-start">Start</span>
                        </li>
                        <li className="active current">
                            <span className="txt-install">Install</span>
                        </li>
                        <li className="">
                            <span className="txt-summary">Summary</span>
                        </li>
                    </ul>

                    <main>
                        <p>
                            <span className="txt-install-summary">These are the steps that will be automatically performed for you!</span>
                        </p>

                        <div className="message">
                            <div className="message-title">
                                <span className="txt-create-role">1. Create Roles</span>
                            </div>
                            <div className="message-content">
                                <div>
                                    {app.isPremium && (
                                        <div className="txt-create-role-msg">Create a Role to provide access to the application.</div>
                                    )}
                                    <div className="txt-create-role-msg">Create a Role for application server side operations.</div>
                                </div>
                            </div>
                        </div>

                        <div className="message">
                            <div className="message-title">
                                <span className="txt-create-oauthclient">
                                    2. Create OAuth Clients
                                    </span>
                            </div>
                            <div className="message-content">
                                <div>
                                    <span className="txt-create-oauthclient-msg">
                                        Create an OAuth Client for application server side operations.
                                        </span>
                                </div>
                            </div>
                        </div>

                        <div className="message">
                            <div className="message-title">
                                <span className="txt-create-elk">
                                    3. Provision Elastic Search and Kibana 
                                    </span>
                            </div>
                            <div className="message-content">
                                <div>
                                    <span className="txt-create-elk-msg">
                                            Provision Elastic Search and Kibana.
                                        </span>
                                </div>
                            </div>
                        </div>

                        <div className="message">
                            <div className="message-title">
                                <span className="txt-create-instance">
                                    4. Configure Dashboards
                                    </span>
                            </div>
                            <div className="message-content">
                                <div>
                                    <span className="txt-create-instance-msg">
                                        Configure Dashboards for online information display.
                                        </span>
                                </div>
                            </div>
                        </div>

                        <p className="sink-more">
                            <span className="txt-start-install">
                                Please click the button to start the installation.
                                </span>
                        </p>

                        <button className="btn-info right" onClick={install}>
                            <span className="txt-install">Install</span>
                        </button>

                    </main>
                </div>
            </Container>
            {loading && (
                <div className="loading-overlay">
                    <div className="loading-overlay-element" >
                        <Spinner color="secondary" />
                        <div className="loading-overlay-text" >{loadingOverlayText}</div>
                    </div>
                </div>
            )}
        </div>
    )
}
export default Install